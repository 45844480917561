import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { ChatMessage, Conversation } from "./MessageItemController.web";
import moment from "moment";
import _ from 'lodash'
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean,
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  activeIndex: number,
  conversationList: Conversation[]
  selectedConversation: Conversation | null
  chatMessage: ChatMessage[]
  cometChatUid: string
  searchKeyword: string
  searchResults: Conversation[]
  lastMessageId: number | null
  hasMoreMessage: boolean
  isLoadingMoreMessages: boolean
  isLoadingInitialMessages: boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      activeIndex: 0,
      conversationList: [],
      selectedConversation: null,
      chatMessage: [],
      cometChatUid: '',
      searchKeyword: '',
      searchResults: [],
      lastMessageId: null,
      hasMoreMessage: true,
      isLoadingMoreMessages: false,
      isLoadingInitialMessages: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.debouncedSearchFriend = _.debounce(this.searchFriend, 300)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authKey: string = localStorage.getItem("cometChatToken") || ''
    if (authKey) {
      this.setState({ cometChatUid: authKey.split('_')[0] })
      this.startCometChat(authKey)
    } else {
      toast.error('This account does not exist in cometchat!')
    }
  }

  startCometChat = async (authKey: string) => {
    this.setState({ isLoading: true })
    if (!CometChat.isInitialized()) {
      console.error("CometChat has not been initialized");
      return;
    }

    CometChat.login(authKey).then(
      user => {
        this.fetchConversations()

        const listenerID = "UNIQUE_LISTENER_ID";
        CometChat.addMessageListener(
          listenerID,
          new CometChat.MessageListener({
            onTextMessageReceived: (message: CometChat.TextMessage) => {
              this.updateChatWhenHasNewMessage(message);
            }
          })
        );
      },
      error => {
        console.log("Login failed with exception:", { error });
      }
    );
  }

  async componentWillUnmount() {
    CometChat.logout().then(
      () => {
        console.log("Logout completed successfully");
      },
      (error: CometChat.CometChatException) => {
        console.log("Logout failed with exception:", error);
      }
    );
    CometChat.removeMessageListener('UNIQUE_LISTENER_ID')
  }

  fetchConversations = async () => {
    const limit = 30;
    const conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(limit)
      .build();

    try {
      const conversationList: CometChat.Conversation[] = await conversationRequest.fetchNext();
      this.customConversationList(conversationList)
    } catch (error) {
      console.log("Conversations list fetching failed with error:", error);
    }
  };

  customConversationList = (conversationList: CometChat.Conversation[]) => {
    if (conversationList) {
      const formattedConversations: Conversation[] = conversationList.map((conversation: CometChat.Conversation) => {
        const lastMessage = conversation.getLastMessage();
        const lastMessageText = lastMessage ? lastMessage.getText() : ''
        const lastMessageTime = lastMessage ? moment.unix(lastMessage.getSentAt()).format('HH:mm') : ''
        const conversationtype = conversation.getConversationType()
        const isUser = conversationtype === 'user'

        if (isUser) {
          const user = conversation.getConversationWith() as CometChat.User;
          return {
            id: user.getUid(),
            type: 'USER',
            name: user.getName(),
            avatar: user.getAvatar(),
            lastMessage: lastMessageText,
            lastMessageTimestamp: lastMessageTime,
            unreadMessageCount: conversation.getUnreadMessageCount(),
          };
        } else {
          const group = conversation.getConversationWith() as CometChat.Group
          return {
            id: group.getGuid(),
            type: 'GROUP',
            name: group.getName(),
            avatar: group.getIcon(),
            lastMessage: lastMessageText,
            lastMessageTimestamp: lastMessageTime,
            unreadMessageCount: conversation.getUnreadMessageCount(),
          }
        }
      });
      if (formattedConversations.length > 0) {
        this.setState({
          conversationList: formattedConversations,
          selectedConversation: formattedConversations[0],
          isLoading: false,
          isLoadingInitialMessages: true,
        })
        this.fetchMessages(formattedConversations[0].id)
      } else {
        this.setState({
          isLoading: false,
        })
      }
    }
  }

  updateChatWhenHasNewMessage = (newMessage: CometChat.TextMessage) => {
    const isUserMessage = newMessage.getReceiverType() === CometChat.RECEIVER_TYPE.USER
    const conversationId = isUserMessage ? newMessage.getSender().getUid() : newMessage.getReceiverId()
    this.setState((prev) => {
      let updatedConversations = [...prev.conversationList];
      const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === conversationId);

      if (conversationIndex !== -1) {
        const updatedConversation = updatedConversations[conversationIndex];
        updatedConversation.lastMessage = newMessage.getText();
        updatedConversation.lastMessageTimestamp = moment.unix(newMessage.getSentAt()).format('HH:mm');
        updatedConversation.unreadMessageCount += 1;

        updatedConversations.splice(conversationIndex, 1);
        updatedConversations.unshift(updatedConversation);
      } else {
        const newConversation: Conversation = {
          id: conversationId,
          type: isUserMessage ? 'USER' : 'GROUP',
          name: isUserMessage ? newMessage.getSender().getName() : newMessage.getReceiver().getName(),
          avatar: isUserMessage ? newMessage.getSender().getAvatar() : '',
          lastMessage: newMessage.getText(),
          lastMessageTimestamp: moment.unix(newMessage.getSentAt()).format('HH:mm'),
          unreadMessageCount: 1,
        };
        updatedConversations.unshift(newConversation);
      }

      return { ...prev, conversationList: updatedConversations }
    })

    if (this.state.selectedConversation && conversationId === this.state.selectedConversation.id) {
      this.setState({
        chatMessage: [
          ...this.state.chatMessage,
          {
            id: newMessage.getId(),
            text: newMessage.getText(),
            sender: {
              id: newMessage.getSender().getUid(),
              name: newMessage.getSender().getName(),
              avatar: newMessage.getSender().getAvatar(),
            },
            timestamp: moment.unix(newMessage.getSentAt()).format('HH:mm')
          }
        ]
      })
    }
  };

  sendMessage = async (message: string) => {
    const selectedConversation = this.state.selectedConversation
    if (selectedConversation) {
      const receiverID: string = selectedConversation.id
      const messageText: string = message
      const receiverType: string = selectedConversation.type === 'GROUP' ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
      const textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);

      CometChat.sendMessage(textMessage).then(
        async (message) => {
          if (message instanceof CometChat.TextMessage) {
            const receiverType = message.getReceiverType()

            if (receiverType === 'user') {
              const receiver = await CometChat.getUser(receiverID)
              this.updateChatWhenSendUserMessage(message, receiver);
            } else {
              const receiver = await CometChat.getGroup(receiverID)
              this.updateChatWhenSendGroupMessage(message, receiver)
            }
          }
        }, (error: CometChat.CometChatException) => {
          console.log("Message sending failed with error:", error);
        }
      );
    }
  }

  updateChatWhenSendUserMessage = async (message: CometChat.TextMessage, receiver: CometChat.User) => {
    this.setState((prev) => {
      let updatedConversations = [...prev.conversationList];
      const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === message.getReceiverId());
      if (conversationIndex !== -1) {
        const updatedConversation = updatedConversations[conversationIndex];
        updatedConversation.lastMessage = message instanceof CometChat.TextMessage ? message.getText() : '';
        updatedConversation.lastMessageTimestamp = moment.unix(message.getSentAt()).format('HH:mm');
        updatedConversation.unreadMessageCount = 0;

        updatedConversations.splice(conversationIndex, 1);
        updatedConversations.unshift(updatedConversation);
      } else {
        const newConversation: Conversation = {
          id: receiver.getUid(),
          type: 'USER',
          name: receiver.getName(),
          avatar: receiver.getAvatar(),
          lastMessage: message instanceof CometChat.TextMessage ? message.getText() : '',
          lastMessageTimestamp: moment.unix(message.getSentAt()).format('HH:mm'),
          unreadMessageCount: 0,
        };
        updatedConversations.unshift(newConversation);
      }

      return {
        ...prev,
        conversationList: updatedConversations,
        chatMessage: [
          ...prev.chatMessage,
          {
            id: message.getId(),
            text: message instanceof CometChat.TextMessage ? message.getText() : '',
            sender: {
              id: message.getSender().getUid(),
              name: message.getSender().getName(),
              avatar: message.getSender().getAvatar(),
            },
            timestamp: moment.unix(message.getSentAt()).format('HH:mm'),
          }
        ]
      }
    })
  }

  updateChatWhenSendGroupMessage = async (message: CometChat.TextMessage, receiver: CometChat.Group) => {
    this.setState((previous) => {
      let updatedConversations = [...previous.conversationList]
      const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === message.getReceiverId())
      if (conversationIndex !== -1) {
        const updatedConversation = updatedConversations[conversationIndex]
        updatedConversation.unreadMessageCount = 0
        updatedConversation.lastMessageTimestamp = moment.unix(message.getSentAt()).format('HH:mm')
        updatedConversation.lastMessage = message instanceof CometChat.TextMessage ? message.getText() : ''

        updatedConversations.splice(conversationIndex, 1)
        updatedConversations.unshift(updatedConversation)
      } else {
        const newConversation: Conversation = {
          id: receiver.getGuid(),
          type: 'GROUP',
          name: receiver.getName(),
          avatar: receiver.getIcon(),
          unreadMessageCount: 0,
          lastMessageTimestamp: moment.unix(message.getSentAt()).format('HH:mm'),
          lastMessage: message instanceof CometChat.TextMessage ? message.getText() : '',
        };
        updatedConversations.unshift(newConversation);
      }

      return {
        ...previous,
        conversationList: updatedConversations,
        chatMessage: [
          ...previous.chatMessage,
          {
            id: message.getId(),
            text: message instanceof CometChat.TextMessage ? message.getText() : '',
            sender: {
              id: message.getSender().getUid(),
              avatar: message.getSender().getAvatar(),
              name: message.getSender().getName(),
            },
            timestamp: moment.unix(message.getSentAt()).format('HH:mm'),
          }
        ]
      }
    })
  }

  debouncedSearchFriend: (keyword: string) => void

  searchFriend = async (keyword: string) => {
    let limit: number = 30;
    let searchKeyword: string = keyword;
    let usersRequest: CometChat.UsersRequest = new CometChat.UsersRequestBuilder()
      .setLimit(limit)
      .setSearchKeyword(searchKeyword)
      .build()

    usersRequest.fetchNext().then(
      (userList: CometChat.User[]) => {
        this.handleSearchFriendResult(userList)
      }, error => {
        console.log("User list fetching failed with error:", error);
      }
    )
  }

  handleSearchFriendResult = (userList: CometChat.User[]) => {
    const formattedUsers: Conversation[] = userList.map((user: CometChat.User) => ({
      id: user.getUid().toString(),
      type: 'USER',
      name: user.getName(),
      avatar: user.getAvatar(),
      lastMessage: '',
      lastMessageTimestamp: '',
      unreadMessageCount: 0,
    }));

    this.setState({ searchResults: formattedUsers });
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyword = event.target.value.trim();
    this.setState({ searchKeyword });
    this.debouncedSearchFriend(searchKeyword);
  };

  fetchMessages = async (conversationId: string) => {
    const limit = 30;
    const messagesRequest = new CometChat.MessagesRequestBuilder()
      .setLimit(limit)

    if (this.state.selectedConversation?.type === 'USER') {
      messagesRequest.setUID(conversationId)
    } else {
      messagesRequest.setGUID(conversationId)
    }

    if (this.state.lastMessageId) {
      messagesRequest.setMessageId(this.state.lastMessageId);
    }

    const builtRequest = messagesRequest.build();
    try {
      const messagesList: CometChat.BaseMessage[] = await builtRequest.fetchPrevious();
      this.handleFetchMessages(messagesList, limit)
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  handleFetchMessages = (messagesList: CometChat.BaseMessage[], limit: number) => {
    if (messagesList) {
      const formattedMessages: ChatMessage[] = messagesList.map((message: CometChat.BaseMessage) => {
        return {
          id: message.getId(),
          text: message instanceof CometChat.TextMessage ? message.getText() : '',
          sender: {
            id: message.getSender().getUid(),
            name: message.getSender().getName(),
            avatar: message.getSender().getAvatar(),
          },
          timestamp: moment.unix(message.getSentAt()).format('HH:mm'),
        };
      });

      this.setState({
        isLoadingInitialMessages: false,
        isLoadingMoreMessages: false,
        hasMoreMessage: messagesList.length === limit,
        chatMessage: [...formattedMessages, ...this.state.chatMessage],
        lastMessageId: formattedMessages.length > 0 ? formattedMessages[0].id : this.state.lastMessageId,
      })
    } else {
      this.setState({ isLoadingInitialMessages: false, hasMoreMessage: false, isLoadingMoreMessages: false, })
      console.error("Expected messagesList to be an array.");
    }
  }

  handleSelectConversation = (conversation: Conversation) => {
    this.setState({
      selectedConversation: conversation,
      chatMessage: [],
      searchKeyword: '',
      searchResults: [],
      lastMessageId: null,
      hasMoreMessage: true,
      isLoadingInitialMessages: true,
    }, () => {
      this.fetchMessages(conversation.id)
    })
  };

  handleLoadMoreMessages = async (conversationId: string) => {
    this.setState({
      isLoadingMoreMessages: true,
    }, () => {
      setTimeout(() => {
        this.fetchMessages(conversationId)
      }, 2000);
    })
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeIndex: newValue })
  }
  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import { EmojiData } from "emoji-mart";
import { ChatMessage, Conversation } from "./MessageItemController.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  checked?: boolean,
  onSendMessage: (message: string) => void
  conversation: Conversation
  cometChatUid: string
  messages: ChatMessage[]
  onLoadMoreMessage: (conversationId: string) => void
  isLoadingMoreMessages: boolean
  isLoadingInitialMessages: boolean
  hasMoreMessages: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  message: string;
  emojiAnchorEl: null | HTMLElement
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  messagesEndRef: RefObject<HTMLDivElement>
  messageContainerRef: RefObject<HTMLDivElement>

  scrollHeightBeforeUpdate: number
  previousScrollTop: number
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      message: "",
      emojiAnchorEl: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.messagesEndRef = React.createRef<HTMLDivElement>()
    this.messageContainerRef = React.createRef<HTMLDivElement>()

    this.scrollHeightBeforeUpdate = 0
    this.previousScrollTop = 0
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.messageContainerRef.current) {
      this.messageContainerRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  async componentWillUnmount() {
    if (this.messageContainerRef.current) {
      this.messageContainerRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const { messages, isLoadingMoreMessages } = this.props;
    const container = this.messageContainerRef.current;

    if (!container) return;

    if (prevProps.isLoadingMoreMessages && !isLoadingMoreMessages) {
      setTimeout(() => {
        const scrollHeightAfterUpdate = container.scrollHeight;
        container.scrollTop = this.previousScrollTop + (scrollHeightAfterUpdate - this.scrollHeightBeforeUpdate)
      }, 0)
      return
    }

    if (prevProps.messages.length === 0 && messages.length > 0) {
      if (container) {
        container.scrollTop = container.scrollHeight;
        return
      }
    }

    if (prevProps.messages.length !== this.props.messages.length) {
      this.scrollToBottom();
    }
  }

  handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ message: event.target.value });
  };

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelect = (emoji: EmojiData) => {
    this.setState({ message: this.state.message + (emoji as any).native })
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleClickSendMessage = () => {
    this.props.onSendMessage(this.state.message)
    this.setState({ message: '' })
  }

  handleScroll = () => {
    const messageContainer = this.messageContainerRef.current

    if (messageContainer) {
      const isScrollTop = messageContainer.scrollTop === 0
      const canScroll = messageContainer.scrollHeight > messageContainer.clientHeight

      if (isScrollTop && !this.props.isLoadingMoreMessages && this.props.hasMoreMessages && canScroll) {
        this.scrollHeightBeforeUpdate = messageContainer.scrollHeight;
        this.previousScrollTop = messageContainer.scrollTop;

        this.props.onLoadMoreMessage(this.props.conversation.id)
      }
    }
  };
  // Customizable Area End
}

import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Icon,
    Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    Typography,
    LinearProgress
} from '@material-ui/core'
import HighlightedStoriesController,{Props,IUserAttributes,OwnerStory} from './HighlightedStoriesController.web';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import PauseIcon from '@material-ui/icons/Pause';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { crossIcon,crossIconLight, eyeIcon,  leftArrow,leftArrowLight, muteIcon,unmuteIcon, profileLogo, rightArrow, sendIcon, shareIcon, likes, storyLikeEmpty, storyLike, rightArrowLight} from "../../broadcastmessage/src/assets";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {StoryViewer} from "../../broadcastmessage/src/BroadcastMessageController.web"
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroller";
import { Stories } from './CrerateHighlightStoriesController.web';

const theme = createTheme({
    palette: {
      primary: {
        main: "#8A24F0",
        contrastText: "#222222",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });




// Customizable Area End


export class HighlightedStories extends HighlightedStoriesController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    
    // Customizable Area Start
      truncateText = (text:string,classes:{[key:string]:string}) => {  
        if(text?.length){  
       if(!this.state.showFullText){ 
          return ( <>
          {text.slice(0, 80)}
          <span onClick={this.setShowFullText} data-test-id="readMoreBtn" className={classes.readMreBtn} >
               {this.state.showFullText ? ' Read less...' : ' Read more...'}
            </span>
        </> )
       }else{
        return <>{text} <span data-test-id="readMoreBtn"  className={classes.readMreBtn}  onClick={this.setShowFullText}>
           Read less...
            </span>
      </>
       }
     }else{
       return ""
     }
   };
 
   renderOwnerLinerBar =(activeIndex:number) =>{  
    return  this.state.ownerStories.map((story: Stories, index: number)=>{
       let progressbarValue 
       if(index == activeIndex){
         progressbarValue =this.state.progress
       }else{
         progressbarValue = index < activeIndex ? 100: 0;
       }
       return  <LinearProgress
          key={index}
         variant="determinate"
         className="progressBar"
         color="primary"
         value={progressbarValue}
       /> }) 
   }
 
 
   storyOwnerDisplay =(classes:{[key:string]:string}) =>{
     const { ownerStories} = this.state; 
   return  <Dialog data-test-id="ownstoryModel" onClose={() =>this.handlelClose()} 
       open={this.props.open} 
       PaperProps={{
         className: this.conditionFilter(!this.props.checked,classes.modelDark,classes.modelLight)
       }}
       classes={{paperWidthLg:this.conditionFilter(!this.props.checked,classes.modelDark,classes.modelLight)}} 
       BackdropProps={{className:this.conditionFilter(!this.props.checked,classes.backdropsAtyleDark,classes.backdropsAtyle)}}
     >       
       <img src={this.conditionFilter(this.props.checked,crossIconLight,crossIcon)} alt="" onClick={()=>this.handlelClose()} className={classes.crossIcon} />
       <Box className={this.conditionFilter(this.props.checked,`${classes.boxStyle} ${classes.boxStyleLight}`,classes.boxStyle)} >
       <Carousel         
           showArrows={true}
           showStatus={false}
           data-test-id="ownstorycarasoul"
           showThumbs={false}
           showIndicators={false}
           selectedItem={this.state.selectedItem}
           emulateTouch={false}
           swipeable={false}
           stopOnHover={false}
           centerMode={true}         
           centerSlidePercentage={100}
           renderArrowPrev={(onClickHandler, hasPrev) =>
             hasPrev && <Icon
             data-test-id="prevBtnSlidder"
             className={classes.leftArrowOwnStyle}
             onClick={()=>this.handlePrevClick()}
           >
             <img src={this.conditionFilter(this.props.checked,leftArrowLight,leftArrow)}  alt="" />
           </Icon>
           }
           renderArrowNext={(onClickHandler, hasNext) =>
             hasNext && <Icon
             data-test-id="nextBtnSlidder"
             className={classes.rightArrowOwnStyle}
             onClick={()=>this.handleNextClick()}
           >
            <img src={this.conditionFilter(this.props.checked,rightArrowLight,rightArrow)}  alt="" /> 
           </Icon>
           }
           className={`${classes.CarouselStyleStart} ${classes.CarouselStyleEnd}`}
         >
          {ownerStories.map((user, index:number) => {              
           const contentType = user.attributes?.media?.content_type;
           let mediaRender;
 
           if (contentType?.startsWith('image')) {
             mediaRender = (
                   <div
                       className="backgroundImage"
                       style={{
                           backgroundImage: `url(${user.attributes?.media.url})`,
                           height: '100%',
                           width: '100%',
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           zIndex: -1
                       }}
                   />
               );
           } else if (contentType?.startsWith('video')) {
             mediaRender = (   
               <ReactPlayer 
                     url = {user.attributes.media.url}
                     controls={false}
                     loading="lazy"
                     className={classes.vidioStyle}
                     autoPlay
                     loop
                     playing={this.state.isPlaying}
                     muted={this.state.isMuted}                 
                />     
               );
           }

           const userInfo = this.state.userInfo as IUserAttributes
 
        return <Box className="sliderBox" key={index} style={{ width: '100%' }}>         
           {mediaRender}
             <div className={classes.innerContain}>
               <Box className={classes.storyHeadStyle}>
                 <div style={{display:"flex",gap:"2px"}}>
                    {this.renderOwnerLinerBar(index)}
                 </div>
                
                 <div className={"storyHead"}>
                   <div className={classes.storyInner}>
                     <img
                       src={this.conditionFilter(userInfo?.profile_photo!==null,userInfo?.profile_photo as string,profileLogo)}
                       alt="img"
                       className={classes.postImage}
                     />
                     <Typography className={"name"}>{userInfo?.full_name}</Typography>
                     <Typography className={"timming"}>{this.getHoursDifference(user.attributes.created_at)}</Typography>
                   </div>
                   <div className={classes.menutBtnOuter}>
                   {this.state.isMuted?<img src={muteIcon} onClick={this.muteHandler} className={classes.muteBtnstyle}  data-test-id="muteId"  alt="" /> :<img src={unmuteIcon} className={classes.muteBtnstyle} data-test-id="muteId" onClick={this.muteHandler}  alt="" /> }
                   {this.state.isPlaying?
                      <PauseIcon className={classes.playStyle} data-test-id="PlayArrowIcon" onClick={this.togglePlayPause} />
                     :<PlayArrowIcon className={classes.playStyle} data-test-id="PlayArrowIcon" onClick={this.togglePlayPause} /> }
                     </div>
                 </div>
               </Box>
               {this.props.isLoggedUser? this.renderLoggedUserBottom(classes,user):this.renderOtherUserBottom(classes,user)}
             </div>
           
         </Box>}
       )}
   </Carousel> 
   </Box> 
  </Dialog>
    }


    renderOtherUserBottom=(classes:{[key:string]:string},user:Stories)=>{
        return (
            <Box className="storyBodyOuter">
                <Typography className={classes.storyBody}  data-test-id ="testing"style={{ color: "white", marginBottom: "10px" }}>
                {this.truncateText(user.attributes?.body,classes) }
                </Typography>
                <Box className={classes.storyFotter}>
                <div style={{ width: "85%" }}>
                    <TextField
                    variant="outlined"
                    data-test-id="replyMessage"
                    InputProps={{
                        classes: {
                        input: classes.inputAtyle,
                        },
                        endAdornment: (
                        <InputAdornment position="end">
                            <img  className={classes.favBtn}  onClick={this.handleSendMessage} data-test-id="sendMessageBtn" src={sendIcon}  alt="" />
                        </InputAdornment>
                        ),
                    }}
                    
                    className={classes.textField}
                    value={this.state.replyMessage}
                    onChange={this.handleChangeMessage}
                    placeholder={"Send Message"} autoComplete="off"
                    />
                </div>
                <div className={classes.likeBtnOuter}>
                    
                    <img src={this.conditionFilter(user.attributes?.is_liked as boolean,storyLike,storyLikeEmpty)} 
                    data-test-id="likeStory"
                    onClick={()=>this.handleUnLikeStory(user.attributes?.id)} alt="" className={classes.likeBtn}
                    />
        
                </div>
                </Box>
            </Box>
    )
    }


   renderLoggedUserBottom=(classes:{[key:string]:string},user:Stories)=>{
    return(
        <Box className="storyBodyOuter">
                 <Typography className={classes.storyBody}  data-test-id ="testing"style={{ color: "white", marginBottom: "10px" }}>
                  {this.truncateText(user.attributes?.body,classes) }
                 </Typography>
                 <Box className={classes.storyfootter1}>
                       <div>
                         <img src={eyeIcon}  data-test-id="viewBtn" onClick={()=>this.handleView()}  className={classes.actionbtn} alt="" />
                         <Typography className={classes.storyHolder} style={{color:"white"}}>{user.attributes.view_count} view</Typography>
                       </div>
                       <div>
                         <img src={shareIcon} alt=""  className={classes.actionbtn} />
                         <Typography className={classes.storyHolder} style={{color:"white"}}>Share</Typography>
                       </div>
                     </Box>
               </Box>
    )
   }


   renderViewer =(classes:{[key:string]:string})=>{
     const {viewerList} = this.state
     
     return <Dialog data-test-id="viewerStoryModel" onClose={() =>this.handlelViewerClose()} 
     open={this.state.showViewer} 
     PaperProps={{
       className: this.conditionFilter(!this.props.checked,classes.modelDark1,classes.modelLight1)
     }}
     classes={{paperWidthSm:this.conditionFilter(!this.props.checked,classes.modelDark1,classes.modelLight1)}} 
     BackdropProps={{className:this.conditionFilter(!this.props.checked,classes.backdropsAtyleDark,classes.backdropsAtyle)}}
    >
      <Grid container  alignItems="center" direction="column" style={{
               rowGap:"25px",width:"425px",height:"310px"                            
         }}>
          <Typography className={classes.name} > Viewed by {this.state.viewerPagination?.total_records}</Typography>
 
         <div className={classes.viewerStoryOuter} ref={this.scrollViewerStoryParentRef}>
           <InfiniteScroll
             pageStart={this.state.page}
             loadMore={this.handleView}
             data-test-id="viewerStoryScroller"
             hasMore={this.state.page!==this.state.viewerPagination?.total_pages}
             loader={<div className={classes.loader} key={0}> {this.state.page !== this.state.viewerPagination?.total_pages&&<CircularProgress disableShrink />}</div>}
             useWindow={false}
             getScrollParent={() => this.scrollViewerStoryParentRef.current}
         >
         {viewerList.map((val:StoryViewer)=> <div className={classes.viewerStory}>
             <div className={classes.viewerStoryInner}>
             <img
               src={this.conditionFilter(val.attributes.viewer.profile_photo!==null,val.attributes.viewer.profile_photo,profileLogo)}
               alt="img"
               className={classes.viewerImage}
             />
             <div>
             <Typography className={classes.viewerName}>{val.attributes.viewer.full_name}</Typography>
             <Typography className={classes.timming}>{this.getHoursDifference(val.attributes.viewed_at)}</Typography>
             </div>
             
           </div>
           <div className={classes.menutBtnOuter}>
              {val.attributes.is_liked&&<img src={likes} alt="" />}
            </div>
             </div>)}
             </InfiniteScroll>
         </div>       
       </Grid>
 
    </Dialog>   
    }  


    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }
    // Customizable Area End
    render(){
    // Customizable Area Start
    const {classes } = this.props
    const theme = this.props.checked?lightTheme:darkTheme
    return(
       <ThemeProvider theme={theme}>
       {this.renderViewer(classes)}
       {this.storyOwnerDisplay(classes)}
       </ThemeProvider>
    )
    // Customizable Area End    
    }
}
// Customizable Area Start

const webStyle = {
    thinColor: {
      boxShadow:"none" as "none",
      borderRadius:"none" as "none"
    },
    readMreBtn:{
      pointerEvents:"auto" as "auto",
      marginLeft:"4px",
      cursor:"pointer", 
      fontWeight:700
    },
    viewerStoryBody:{
     display:"flex",
     flexDirection:"column" as "column",
     rowGap:"25px",
     width:"100%",
     alignItems:"center"
    },
    name:{
      fontWeight:800,
      fontFamily:"Manrope",
      fontSize:"18px",
    },
    color:{ 
      boxShadow:"none" as "none",
      borderRadius:"none" as "none"
    },
    rightArrowOwnStyle:{
      transform: 'translateY(-50%)',
      zIndex: 2,
      pointerEvents:"auto" as "auto",
      height:"auto",
      width:"auto",
      position: 'absolute' as "absolute", 
      right: "28%",
      top: '50%',    
    },
    rightArrowStyle:{
      top: '50%',
     transform: 'translateY(-50%)',
     zIndex: 2,
     pointerEvents:"auto" as "auto",
     height:"auto",
     width:"auto" ,
     position: 'absolute' as "absolute", 
     right: "29%",   
    },
    muteBtnstyle:{
      pointerEvents:"auto !important" as "auto",
      cursor:"pointer"
    },
    loader:{
      display:"flex",
      width:"100%",
      justifyContent:"center" as "center"
    },
    leftArrowOwnStyle:{
      position: 'absolute' as "absolute", 
      left: "28%",
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      pointerEvents:"auto" as "auto",
      height:"auto",
      width:"auto", 
      cursor:"pointer"
    },
    leftArrowStyle:{
      position: 'absolute' as "absolute", 
      left: "29%",
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      pointerEvents:"auto" as "auto",
      height:"auto",
      width:"auto",
      cursor:"pointer" 
    },
    backdropsAtyle:{
      background:"rgba(255, 255, 255, 0.9)", 
      backdropFilter: "blur(1px)",
    },
    vidioStyle:{
        position: 'absolute' as "absolute",
        top: 0,
        left: 0,
        height: '100% !important',
        width: '100% !important',
        zIndex: -1,
        background:"black"
    },
    innerContain:{
      display:"flex",
      flexDirection:"column" as "column",
      justifyContent:"space-between",
      height:"100%",
      rowGap:"10px"
    },
    storyHeadStyle:{
      backgroundImage: "linear-gradient(360deg, rgba(81, 78, 78, 0.2) 80%, rgba(93, 90, 90, 0.2) 80%)",
      paddingBottom:"14px"
    },
    postImage:{
      height:"35px ",
      width:"35px !important",
      borderRadius:"50%",
      objectFit:"cover" as "cover"
    },
    viewerImage:{
      height:"48px ",
      width:"48px !important",
      borderRadius:"50%",
    },
    postImage1:{
      height:"83px !important",
      width:"83px !important",
      borderRadius:"50%",
      objectFit:"cover" as "cover",
    }, 
    postImage1Outer1:{
      height:"89px !important",
      width:"89px !important",
      borderRadius:"50%",
      objectFit:"cover" as "cover",
      border:"3px solid #222222",
    },
    postImage1Outer:{
      height:"89px !important",
      width:"89px !important",
      border:"3px solid #8A24F0",
      borderRadius:"50%",
    },
    playStyle:{
      color: 'white',
      pointerEvents: "auto" as "auto",
      cursor:"pointer"
    },
    inputAtyle:{
     color:"white",
     height:"18px"
    },
    textField: {
      width: '100%',
      height:"50px",
      padding:"2px",
      pointerEvents: "auto" as "auto",
    },
    likeBtnOuter:{
      width: '15%', 
      display:"flex",
      marginTop:"4px"
    },
    likeBtn:{
     width:"35px",
     height:"35px",
     cursor:"pointer",
     pointerEvents: "auto !important" as "auto",
    },
    storyFotter:{
     width:"100%",
     display:"flex",
     justifyContent:"space-between",
     padding:"10px 20px",
     backgroundImage: "linear-gradient(360deg,rgba(38,38,38,.4) 0%,rgba(38,38,38,0) 100%)"      
    },
    storyfootter1:{
     width:"100%",
     display:"flex",
     justifyContent:"space-between",
     padding:"10px 20px",
    },
    favBtn:{
      cursor:"pointer",
      pointerEvents: "auto !important" as "auto",
    },
    actionbtn:{
     cursor:"pointer",
     pointerEvents: "auto !important" as "auto",
    },
    backdropsAtyleDark:{
      background: "rgba(8,4,8,0.9) !important" ,
      backdropFilter: "blur(1px)",
    },
    menutBtnOuter:{
     display:"flex",
     gap:"10px",
     alignItems:"center"
    },
    modelLight:{
      borderRadius: "10px", 
      maxWidth:"1820px",   
      width:"1820px",      
      backdropFilter: "blur(1 px)", 
      boxShadow:"none" as "none",
      background:"transparent",   
     },
     viewerName:{
      fontFamily:"Manrope",
      fontWeight:700,
      fontSize:"16px",
     },
     timming:{
      fontFamily:"Manrope",
      fontWeight:500,
      fontSize:"12px",
      color:"6B6B6B"
     },
     modelLight1:{
      borderRadius: "10px", 
      boxShadow:"none",
      border: "1px solid transparent",
      background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",  
      backdropFilter: "blur(1 px)", 
      padding: "25px"   
     },
    modelDark:{ 
      maxWidth:"1820px",
      width:"1820px",
      boxShadow:"none" as "none" ,
      background:"transparent",
      backdropFilter: "blur(4px)",   
    },
    modelDark1:{  
      borderRadius: "10px",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border: "1px solid transparent",
      backdropFilter: "blur(1 px)",
      background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
      boxShadow:"none",  
      padding: "25px" 
    },
    storyImage:{
      height:"56px",
      width:"56px",
      borderRadius:"50%",
      objectFit:"cover" as "cover",
      border: "2px dashed var(--Primary-Purple-100, #8A24F0)",
    },
    ownStoryImage:{
      height:"56px",
      width:"56px",
      borderRadius:"50%",
      objectFit:"cover" as "cover",
      border: "2px dashed #649BF5",
    },  
    story:{
      display:"flex !important",
      flexDirection:"column" as "column",
      alignItems:"center" as "center",
      height:"83px",
      gap:"5px",
      margin: '0 2px'
    },
    skeletonContainer: {
      display: 'flex !important',
      flexDirection: 'column !important' as "column",
      alignItems: 'center !important' as "column",
      margin: '0 4px', 
    },
    storyHolder:{
      fontFamily:"Manrope",
      fontWeight:500,
      fontSize:"10px",
      overFlow:"hidden"
    },
    ownStoryStyle:{
      fontFamily:"Manrope",
      fontWeight:500,
      fontSize:"12px",
      overFlow:"hidden"
    },
    storyBody:{
      fontFamily:"Manrope",
      fontWeight:500,
      fontSize:"14px",
      padding:"0px 20px" ,
      textAlign:"start" as "start",
    },
    custom_slider:{
      "& .slick-track": {
        display: "flex",
        gap:"15px",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      '& .slick-next::before': {      
       color:'black' ,
       fontSize: "23px"   
      },
     '& .slick-prev::before':{
      color: 'black',
      fontSize: "23px"          
     },
     '& .slick-next': {      
      transform: "translate(0, -98%) !important"    
     },
    '& .slick-prev':{
     transform: "translate(0, -98%) !important"      
    }
    },
    custom_sliderDark:{
      "& .slick-track": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:"15px",
        
      },
      '& .slick-next::before': {         
        fontSize: "23px"   
       },
      '& .slick-prev::before':{
       fontSize: "23px"          
      },
      '& .slick-next': {      
        transform: "translate(0, -98%) !important"    
       },
      '& .slick-prev':{
       transform: "translate(0, -98%) !important"      
      }
    },
    settings:{
      infinite: false,
      speed: 200,
      slidesToScroll: 3,
      initialSlide: 0,    
      swipe: false, 
      touchMove: false, 
      pauseOnHover: true, 
      display: "flex",
      height: "88px",
      padding:"0px 15px 15px 14px", 
      gap:"25px",
      width: "100%",
    },
    crossIcon:{
      width:"48px",
      height:"48px",
      position:"absolute" as "absolute",
      top:0,
      right:0,
      cursor:"pointer"
    },
    CarouselStyleStart:{
      "& .carousel-slider":{
        paddingLeft:"33.5%",
      }
    },
    CarouselStyleEnd:{
      "& .carousel-slider":{
        paddingRight:"33.5%",
      }
    },
    boxStyle:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column" as "column",
      width: "100%",
      height:"100%",
      "& .carousel-root":{
          width: "100%",
          height:"80vh",
          maxWidth:"1280px",
          display:"flex",
          justifyContent:"center" as "center",
          alignItems:"center",
          gap:"10px",
          pointerEvents: "none" as "none",
      },
      "& .carousel.carousel-slider .control-arrow": {
        display: "block",
        background: "none" as "none",
        cursor: "pointer",
        zIndex: 1,
      },
      "& .carousel .control-prev.control-arrow": {
        left: "30%",
        pointerEvents: "auto",
      },
      "& .carousel .control-next.control-arrow": {
        right: "30%",
        pointerEvents: "auto",
      },
      "& .selected":{
          "& .sliderBox":{
              transform: "scale(1.0)",
              opacity:1,
          },
          "& .progressBar":{
              
          },
          "& .storyBodyOuter":{
             display:"block",
             backgroundImage: "linear-gradient(360deg, rgb(8 8 8 / 60%) 100%, rgb(20 17 17 / 60%) 80%)"
            },
          "& .storyHead":{
            display:"flex",
            justifyContent:"space-between",
            padding:"0 8px",
            marginTop:"10px",
          },
          "& .storyHead1":{
            display:"none" as "none",
          }
  },
  "& .sliderBox": {
      opacity: 0.5,
      boxSizing: "border-box",
      transform: "scale(0.7)" ,
      transition: "transform 0.5s",
      height:"80vh",
      maxHeight:"1200px",
      position:"relative",
      pointerEvents: "none",
      "& .backgroundImage":{
        width: "100%",
        height: "100%",
        display: "block",
        backgroundSize: "100% 100%",
        background:"black"
      },
      "& .progressBar":{
        marginTop:"10px",
        borderRadius:"10px",
        height:3,
        background:"#2b2828",
        width:"100%"
      },
      "& .storyHead":{
        display:"none",
      },
      "& .storyBodyOuter":{
        display:"none"
      },
      "& .storyHead1":{
      display:"flex",
      gap:"5px",
      alignItems:"center",
      marginTop:"10px",
      postion:"absolute",
      bottom:"40px",
      position: "absolute",
      width:"100%",
      backgroundImage: "linear-gradient(360deg, rgb(8 8 8 / 60%) 100%, rgb(20 17 17 / 60%) 80%)",
      flexDirection: "column" as "column",
      padding:"4px"
    }
    },
    "& .sliderBoxOne": {
      opacity: 1,
      padding: "20px",
      boxSizing: "border-box",
      transform: "scale(1)" ,
      transition: "transform 0.5s"
    },
    "& .name": {
      fontSize:"18px",
      fontFamily:"Manrope",
      fontWeight:800,
      lineHeight:"21px",
      color:"white",
      [theme.breakpoints.down(650)]: {
        fontSize: "16px",
      }
    },
    "& .timming":{
      fontSize:"12px",
      fontFamily:"Manrope",
      fontWeight:500, 
      color:"white",  
      [theme.breakpoints.down(650)]: {
        fontSize: "06px",
      }
    },
    "& .nameimg": {
      display: "flex",
      alignItems: "center",
      gap: 15,
    },
    "& .quotes": {
      width: "35px !important",
    },
    "& .card": {   
      padding: "15px",
      margin: "20px",
      height: "225px",
      border: "none",
      [theme.breakpoints.down(600)]: {
        margin: "0px"
      }
    },
    "& .singleCard": {
      backgroundColor: "#ffffff",
      padding: "15px",
      margin: "20px",
      height: "225px",
      width: "525px",
      border: "none",
      [theme.breakpoints.down(600)]: {
        margin: "0px",
        width: "335px"
      }
    },
    },
    boxStyleLight:{
      "& .carousel .control-prev.control-arrow::before": {
        left: "30%",
        pointerEvents: "auto",
        borderRight:" 8px solid black  !important",
      },
      "& .carousel .control-next.control-arrow::before": {
        right: "30%",
        pointerEvents: "auto",
        borderLeft:" 8px solid black  !important",
      },
    },
    viewerStoryOuter:{
      width:"100%",
      display:"flex",
      flexDirection:"column" as "column",
      rowGap:"15px",
      maxHeight:"250px",
      overflowY:"scroll" as "scroll"
    },
    viewerStory:{
      display:"flex",
      justifyContent:"space-between",
      width:"100%",
      height:"48px",
      marginBottom:"15px"
    },
    storyInner:{
      display:"flex",
      gap:"5px",
      alignItems:"center"
    },
    viewerStoryInner:{
      display:"flex",
      columnGap:"12px",
      alignItems:"center" as "center"
  
    }
  };
  
  export default  withStyles(webStyle)(HighlightedStories)

// Customizable Area End
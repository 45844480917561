// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Conversation {
  id: string;
  type: 'USER' | 'GROUP'
  name: string;
  avatar: string;
  lastMessage: string;
  lastMessageTimestamp: string;
  unreadMessageCount: number;
}
export interface ChatMessage {
  id: number;
  text: string;
  sender: {
    id: string
    name: string
    avatar: string
  }
  timestamp: string;
}

export interface Props {
  classes?: any
  conversation: Conversation
  onClickConversation: (conversation: Conversation) => void
  isSelectingConversation: boolean
}

interface S {
}

interface SS {
}

export default class MessageItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
// Customizable Area End

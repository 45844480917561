export const edit = require("../assets/edit.png");
export const AvatarImage = require("../assets/avatarImage.png");
export const Mic = require("../assets/mic.svg");
export const video = require("../assets/video.svg");
export const addPost = require("../assets/addPost.svg");
export const location = require("../assets/location.svg");
export const camera = require("../assets/camera.svg");
export const Uploading = require("../assets/Uploading.svg");
export const lightMic = require("../assets/lightMic.svg");
export const lightVideo = require("../assets/lightVideo.svg");
export const lightAddPost = require("../assets/lightAddPost.svg");
export const lightLocation = require("../assets/lightLocation.svg");
export const lightCamera = require("../assets/lightCamera.svg");
export const lightUploading = require("../assets/lightUploading.svg");
export const gif = require("../assets/gif.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const audience = require("../assets/audience.svg");
export const checkbox=require("../assets/checkbox_.svg");
export const lightCheckbox=require("../assets/lightCheckBox.svg");
export const darkCheckBox=require("../assets/darkCheckBox.svg");
export const darkView=require("../assets/dark_view.svg");
export const view=require("../assets/view_.svg");
export const lightRightAngle=require("../assets/light_rightAngle.svg");
export const darkRightAngle=require("../assets/dark_right_angle.svg");
export const closeLightBtn = require("../assets/closeLightBtn.svg");
export const emotion = require("../assets/emotion.svg");
export const emotionLight = require("../assets/emotionLight.svg");
export const attachments = require("../assets/attachments.svg");
export const attachmentsLight = require("../assets/attachmentsLight.svg");
export const searchLight = require("../assets/searchLight.svg");
export const warningIcon = require("../assets/warningIcon.svg");
export const checkIcon = require("../assets/checkIcon.svg");
export const leftArrowIcon = require("../assets/leftArrowIcon.svg");
export const downArrowIcon = require("../assets/downArrowIcon.svg");
export const liveIcon = require("../assets/liveIcon.svg");
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Post } from "./ActivityFeedController";

export const configJSON = require("./config");

export interface Props {
  classes?: any
  postData: Post
  userInfo: {
    id: number
  },
  checked: boolean,
  onFollow: (id: number, type: string) => void,
  onClickMoreOption: (activeUser: any, postId: string) => void
  onMuteConformation: () => void
  onBlockAccount: (id: number) => void
  onShowReportPost: () => void
}

interface S {
  anchorEl: null | HTMLElement
}

interface SS {
  screenId: string;
}


export default class PostHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  getMediaApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];


    this.state = {
      anchorEl: null
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  };

}
// Customizable Area End
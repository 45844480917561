import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Popover,
  withStyles,
  createStyles,
  styled,
  CircularProgress
} from "@material-ui/core";
import { backArrowDark, backArrowWhite, callDark, callLight, emojiIcon, profile, searchDark, searchWhite, sendIcon, threeDots, threeDotsLight } from "./assets";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMessages = (classes: any) => {
    const { messages, cometChatUid } = this.props

    return (
      <>
        {messages.map((message) => (
          <div
            key={message.id}
            className={classes.messageItemContainer}
            style={{
              justifyContent: message.sender.id === cometChatUid ? 'flex-end' : 'flex-start'
            }}
          >
            {message.sender.id !== cometChatUid && (
              <img src={message.sender.avatar || profile} style={{width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px'}} />
            )}
            <Box
              className={classes.messageItemWrapper}
              style={{ background: message.sender.id === cometChatUid ? 'linear-gradient(-45deg, #3858E3, #BA05F7)' : '#222222' }}
            >
              <Typography className={classes.messageTextContent}>{message.text}</Typography>
              <Typography className={classes.messageTime}>{message.timestamp}</Typography>
            </Box>
          </div>
        ))}
      </>
    );
  };

  renderEmotionButton = () => {
    const { emojiAnchorEl } = this.state;
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
      <Box>
        <IconButton data-testid="emoji-picker-button" onClick={this.handleClickEmoji}>
          <img src={emojiIcon} />
        </IconButton>
        <EmojiPopover
          id={id}
          open={open}
          anchorEl={emojiAnchorEl}
          onClose={this.handleCloseEmoji}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ marginTop: -66 }}
        >
          <Picker
            data-testid="emoji-picker"
            theme="dark"
            onSelect={this.handleSelect}
            autoFocus
          />
        </EmojiPopover>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { checked, conversation, classes, isLoadingInitialMessages, isLoadingMoreMessages } = this.props;

    return (
      <Box data-test-id="viewChatId" className={classes.container}>
        <Box className={classes.chatHeader}>
          <Box className={classes.chatHeaderItem}>
            <span className={classes.iconWrapper}>
              <img className={classes.icon} src={checked ? backArrowDark : backArrowWhite} />
            </span>
            <img className={classes.headerProfileImg} style={{ border: checked ? "1px solid #D1B7F5" : "1px solid #FFFF" }} src={conversation.avatar || profile} alt="" />
            <Typography className={classes.headerProfileName}>{conversation.name}</Typography>
          </Box>
          <Box className={classes.chatHeaderItem}>
            <span className={classes.iconWrapper}><img className={classes.icon} src={checked ? callDark : callLight} alt="" /></span>
            <span className={classes.iconWrapper}><img className={classes.icon} src={checked ? searchDark : searchWhite} alt="" /></span>
            <span className={classes.iconWrapper}><img className={classes.icon} src={checked ? threeDots : threeDotsLight} alt="" /></span>
          </Box>
        </Box>
        <div className={classes.messagesContainer} ref={this.messageContainerRef}>
          <Box className={classes.messageProfileWrapper}>
            <img
              className={classes.messageProfileImg}
              style={{ border: checked ? "1px solid #D1B7F5" : "1px solid #FFFF" }}
              src={conversation.avatar || profile}
              alt=""
            />
            <Typography className={classes.messageProfileName}>{conversation.name}</Typography>
          </Box>
          {(isLoadingMoreMessages || isLoadingInitialMessages) && (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={32} style={{ color: '#8a33f0' }} />
            </Box>
          )}
          {this.renderMessages(classes)}
          <div ref={this.messagesEndRef} />
        </div>

        <div className={classes.footerWrapper}>
          <TextField
            data-testid="messageInput"
            variant="outlined"
            placeholder="Message..."
            className={classes.messageField}
            fullWidth
            data-test-id="messageInputId"
            value={this.state.message}
            onChange={this.handleMessageChange}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  {this.renderEmotionButton()}
                </InputAdornment>
              )
            }}
          />
          <Button
            className={classes.sendMessageBtn}
            onClick={this.handleClickSendMessage}
            disabled={this.state.message.trim().length === 0}
          >
            <img src={sendIcon} alt="" />
          </Button>
        </div>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  container: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  chatHeader: {
    paddingTop: '15px',
    display: "flex",
    justifyContent: "space-between"
  },
  chatHeaderItem: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: "24px",
    height: "24px",
    padding: '10px 12px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  headerProfileImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  headerProfileName: {
    fontSize: "24px",
    fontWeight: 800,
    fontFamily: "Manrope",
    textTransform: "capitalize",
    marginLeft: '12px',
  },
  messagesContainer: {
    height: "calc(100vh - 205px)",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  messageProfileWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center"
  },
  messageProfileImg: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  messageProfileName: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  footerWrapper: {
    display: "flex",
    gap: "10px",
  },
  messageField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px",
      '&.Mui-focused fieldset': {
        border: "1px solid #7A2BF5",
      },
    },
    width: "100%"
  },
  sendMessageBtn: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: "56px",
    width: "56px",
    borderRadius: "16px",
    padding: '10px',
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
    },
  },
  messageItemContainer: {
    display: 'flex',
    margin: '10px 0'
  },
  messageItemWrapper: {
    maxWidth: '60%',
    padding: '12px',
    borderRadius: '10px',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    display: 'flex',
    gap: '12px',
    alignItems: 'baseline',
  },
  messageTextContent: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '14px',
  },
  messageTime: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '10px',
  },
})

const EmojiPopover = styled(Popover)({
  '& .emoji-mart-search': {
    '& input': {
      borderColor: '#ae65c5',
    },
  },

  '& .emoji-mart-anchor': {
    cursor: 'pointer',
  },

  '& .emoji-mart-emoji': {
    '& span': {
      cursor: 'pointer !important',
    }
  },
})

export const ConversationContent = withStyles(styles)(ViewChat)
// Customizable Area End

import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import React from "react"
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Account, StoryViewer} from "../../broadcastmessage/src/BroadcastMessageController.web";
import { Stories } from "./CrerateHighlightStoriesController.web";
import { IHighlights } from "./ProfileController.web";

interface IStoriesResponse{
  data:Array<Stories>
}

interface IError{
  errors:Array<string>
}

interface PaginationDetails {
    page_number: number;
    records_in_this_page: number;
    records_per_page: number;
    total_pages: number;
    total_records: number;
  }


  interface Media {
    id: number;
    url: string;
    filename: string;
    content_type: string;
  }
  
  export interface OwnerStory {
    id: string;
    type: string;
    attributes: {
    id: number;
    body: string;
    media: Media;
    created_at: string;
    is_viewed: boolean;
    hash_tags: string[];
    is_liked:boolean|null,
    body_mentions: string[];
    view_count: number;
    };
  }


export interface IUserAttributes{
    full_name:string,
    profile_photo:string|null
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    checked:boolean;
    open:boolean;
    HighlightId:IHighlights
    onClose:()=>void;
    classes:{[key:string]:string}
    isLoggedUser:boolean
    // Customizable Area End
  }
  
  interface S {
     // Customizable Area Start
     showViewer:boolean
     isPlaying:boolean
     isMuted:boolean
     isModel:boolean
     timer: null |NodeJS.Timer
     progress:number,
     replyMessage:string,
     ownerStories:Array<Stories>,
     selectedItem:number
     page:number
     viewerPagination:PaginationDetails,
     userStories:Array<Account>,
     isLoading:boolean,
     isDisabled:boolean
     childIndex:number,
     isOwnerModel:boolean
     userInfo:{}|IUserAttributes,
     viewerList:StoryViewer[],
     showFullText:boolean
    // Customizable Area End
  }
  
  interface SS {
    // Customizable Area Start
     id: any;
    // Customizable Area End
  }
  
export default class HighlightedStoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  seenOwnerStoriesApiCallId:string=""
  seenStoriesApiCallId:string=""
  getHighlightApiCallId:string=""
  scrollViewerStoryParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
    showViewer:false,
    isPlaying:true,
    isMuted:false,
    isModel:false,
    timer:null,
    progress:0,
    replyMessage:'',
    selectedItem:0,
    page:0,
    ownerStories:[],
    viewerPagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0        
      },
      userStories:[],
      isLoading:false,
      isDisabled:false,
      childIndex:0,
      userInfo:{},
      viewerList:[],
      isOwnerModel:false,
      showFullText:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponse(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
      if(this.props.open!==prevProps.open){
        if(this.props.open){
            this.setState({
                isMuted:false,
                isPlaying:true
            })
            this.getHighlightStories()
        }
      }
  }


  handlelViewerClose =()=>{
    this.setState({
        showViewer:false,
    })
  }


  handlelClose =()=>{
    this.setState({
        isModel:false,
        isPlaying:true,
        isMuted:true,
    })
    this.props.onClose()
   }
    

   muteHandler =()=>{
    this.setState({isMuted:!this.state.isMuted})
  }


  startProgress = () => {
    clearInterval(this.state.timer as unknown as number); 
    if (this.state.isPlaying) {
    const interval = setInterval(() => {
      this.setState(prevState => ({progress: prevState.progress + (100 / 300)}), () => {
        if (this.state.progress >= 100) {
          clearInterval(this.state.timer as unknown as number);
          this.setState({ progress: 100})
        }
      });
    }, 100); 
    this.setState({ timer: interval as NodeJS.Timer });
  }
  };
  
  pauseProgress = () => {
    clearInterval(this.state.timer as unknown as number);
    this.setState({ isPlaying: false });
  };

  togglePlayPause = () => {
    this.setState(prevState => {
      if (prevState.isPlaying) {
        this.pauseProgress();
      } else {
        this.setState({ isPlaying: true }, this.startProgress);
      }
    });
  };

   getHoursDifference = (dateString:string) => {    
    const createdAt = new Date(dateString) as unknown as number;
    const now = new Date() as unknown as number;
    const diffInMilliseconds = now - createdAt;
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    return diffInHours < 1 ? "just now" : `${diffInHours} h`;  
   };

   conditionFilter = (condition:boolean,trueStatement:string,falseStatement:string) =>{
    return condition ?  trueStatement :falseStatement
   }

   handleLikeStory= async(id:number)=>{
    
    let token = await getStorageData("authToken")

    return token
   }


   handleUnLikeStory  = async(id:number)=>{
    let token = await getStorageData("authToken")
    return    token
   }

   handleView=()=>{
    this.pauseProgress()
    this.handleSeenOwnStories()
    this.setState({showViewer:true})
  }

  handleSeenOwnStories =async() =>{
    this.setState({
        
    })
    let token = await getStorageData("authToken")
    let user_details =  this.state.ownerStories[this.state.selectedItem]
    if(!user_details.id) {
      return
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.seenOwnerStoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getownSennStoriesAPiEndPoint+`?id=${user_details?.id}&page=${this.state.page}&_limit=15&sort_order=desc`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }


  getHighlightStories =async() =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token:token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHighlightApiCallId= requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addHighlightEndPoint+`/${this.props.HighlightId.id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  setShowFullText =()=>{
    this.setState({showFullText:!this.state.showFullText})
   }



   handlePrev =()=>{
    if (this.state.childIndex > 0) {
      setTimeout(()=>{
        this.setState({ isPlaying: true,childIndex:this.state.childIndex?this.state.childIndex-1:0,isMuted:true,showFullText:false,isDisabled:false},()=>{
          this.startProgress(); 
          this.handleSeenStories()
       }) 
      },500)     
  
    } else if(this.state.selectedItem>0){ 
    setTimeout(()=>{
      this.setState({ selectedItem:this.state.selectedItem?this.state.selectedItem-1:0,isPlaying: true,childIndex:0,isMuted:true,showFullText:false,isDisabled:false},()=>{
        this.startProgress(); 
        this.handleSeenStories()
     }) 
    },500) }
   }


  
   handlePrevClick = () => {
    this.setState({isDisabled:true})
    this.resetProgress();   
    if(this.props.open){
     this.handlePrev()
  }else{
    if (this.state.selectedItem > 0) {
      setTimeout(()=>{
        this.setState({ isPlaying: true,isMuted:true,selectedItem: this.state.selectedItem?this.state.selectedItem-1:0,showFullText:false,isDisabled:false},()=>{
          this.startProgress(); 
    
       }) 
      },500)    
    } 
  }
    
  };

  handleResponse=(apiRequestCallId:string,responseJson:IStoriesResponse&IError)=>{
     if(apiRequestCallId===this.getHighlightApiCallId){
        return this.handlegetHighlight(responseJson)
     }
  }

  
  handlegetHighlight=(responseJson:IStoriesResponse&IError)=>{
    if(!responseJson?.errors){
     this.setState({
      ownerStories:responseJson.data
     },this.startProgress) 
    }
  }



   resetProgress = () => {
    clearInterval(this.state.timer as unknown as number);
    this.setState({ progress: 0 ,timer: null});
  };
  
  

   handleSendMessage =()=>{
    this.setState({replyMessage:"",isPlaying: true},()=>{
      this.startProgress()
    })
   }


   handleSeenStories =async() =>{
    let token = await getStorageData("authToken")
    return token
  }

   
   handleNextClick = () => {
    this.setState({isDisabled:true})
    this.resetProgress();
    if (this.state.selectedItem <this.state.ownerStories.length-1) {
      setTimeout(()=>{
        this.setState({ isPlaying: true,isMuted:true,selectedItem: this.state.selectedItem+1,showFullText:false,isDisabled:false},()=>{
          this.startProgress();   
         
       }) 
      },500)    
    } else{
      this.handlelClose()
    }
  };
  




   handleChangeMessage =(event:React.ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value){
    this.setState({replyMessage:event.target.value},()=>{
      this.pauseProgress()
   })}else{
    this.setState({replyMessage:event.target.value,isPlaying: true},()=>{
       this.startProgress()
    })
   }}
  // Customizable Area End
}
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { ReactNode } from "react";
import storage from "../../../framework/src/StorageProvider";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string}
  children?:ReactNode
  checked:boolean
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  dateOfBirth:string;
  checked:boolean;
  disable:boolean,
  showCalendar:boolean
  anchorEl:null | HTMLElement
  calendarDate:string | Date
  errors:{nameError:string,phoneNumberError:string,dobError:string,emailError:string};
  error:string
  openModal:boolean,
  checkboxError:any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DateOfBirthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dateOfBirthAPIId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.dateOfBirthAPIId=""
    this.state = {
      // Customizable Area Start
      dateOfBirth:"",
      checked:false,
      disable:true,
      showCalendar:false,
      anchorEl:null,
      calendarDate:"",
      errors:{nameError:'',phoneNumberError:'',dobError:'',emailError:''},
      error:'',
      openModal:false,
      checkboxError:"",
     
      // Customizable Area End
    };

    // Customizable Area Start
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.dateOfBirthAPIId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          console.log("in dob",responseJson);
          
          this.navigateToUserName(responseJson)

        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
         
        }
    }
  }



 
    // Customizable Area End
  }

  // Customizable Area Start

  



  goToPrivacyPolicy() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToTermsAndCondition() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

 

  handleInputClick = (event:React.MouseEvent<HTMLElement>) => {
  this.setState({
    anchorEl: event.currentTarget
  })
  };


  handleCloseCalendar=()=>{
    this.setState({
      anchorEl:null
    })
  }

  handleDobChange=(val: Value)=>{
    const getFormatDate = this.formatDate(val as Date)
    this.setState({
      dateOfBirth:getFormatDate.dobDate,
      calendarDate:val as Date,
      anchorEl:null
    },()=>{ 
    })
    this.validateDob(getFormatDate.validateDate)
    
  }

   formatDate = (date:Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return {validateDate:`${year}-${month}-${day}`, dobDate:`${day}-${month}-${year}`};
  };

  validateDob=(value:string)=>{
    const parts = value.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const currentDay = parseInt(parts[2], 10);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentDateOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1 
    if (value==='dd-mm-yyyy'){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.selectDate}
      }));

    }
    else if (currentDay>=currentDateOfMonth && year>=currentYear && month>=currentMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (currentDay>=currentDateOfMonth && year>currentYear && month<currentMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (month>currentMonth && year>=currentYear && currentDay<currentDateOfMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (year>currentYear){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }));

    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError: '' }
      }));

    }
  }


navigateToTermsAndConditions = () => {
  this.setState({ openModal: true });
};
handleClose = () => {
  this.setState({ openModal: false });
};

handleTermsAndConditions = () => {
  this.setState({
    checked: true,
    openModal: false,
  });
};

handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ checked: event.target.checked }, () => {
  });
  if (event.target.checked === false) {
    this.setState({ checkboxError: configJSON.termsAndConditions });
  } else {
    this.setState({ checkboxError: "" });
  }
};

handleDateOfBirthAPI = async () => {
  const submitProposals = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
    'Content-Type': configJSON.contentTypeApiAddDetail,
    "token": await storage.get("authToken"),
  };

  const requestData = {
    proposals: {
      date_of_birth:this.state.dateOfBirth
    },
    token: await storage.get("authToken")
  };
  this.dateOfBirthAPIId = submitProposals.messageId;
  submitProposals.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.dateOfBirthApiEndPoint
  );
  submitProposals.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  submitProposals.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(requestData)
  );
  submitProposals.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypePatch
  );
  runEngine.sendMessage(submitProposals.id, submitProposals);
 
}

navigateToUserName=(responseJson:any)=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "OTPInputAuth"
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {screen:2,response:responseJson});
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

navigateToProfile=(route:string,profile?:boolean)=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    route
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    profile:profile,
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

handleGoogleNavigationLogin = (userObject: {
  profilePhoto: string | null,
  followingCount: number,
  followersCount: number, userSubscription: string,
  userOwnership: boolean, categories: boolean,
  subCategories: boolean
}, responseJson: any) => {
  if (Object.values(userObject)[0] === null) {
    this.navigateToUserName(responseJson)

  }
  else if (Object.values(userObject)[5] === false) {
    this.navigateToProfile('Categories')
  }
  else if (Object.values(userObject)[6] === false) {
    this.navigateToProfile('Categories', true)
  }
  else if (Object.values(userObject)[4] === false) {
    this.navigateToProfile('BecomeOwner')
  }
  else if (Object.values(userObject)[3] !== '' && (Object.values(userObject)[2] === 0 && Object.values(userObject)[1] === 0)) {
    this.navigateToProfile('Followers', true)
  }
  else if (Object.values(userObject)[2] !== 0 || Object.values(userObject)[1] !== 0) {
    this.navigateToProfile('LandingPage')
  }
  else {
    this.navigateToProfile('LandingPage')
  }

}

  // Customizable Area End
}
// Customizable Area End


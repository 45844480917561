import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActivityInterface } from "./dataInterface";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import {debounce, cloneDeep} from 'lodash';
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from "react-toastify";

export interface PostActivityInterface {
  impressions: number
  engagements: number;
  detail_expands: number;
  new_followers: number;
  profile_visits: number
}

export interface User {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string | null;
  country_code: string | null;
  phone_number: number | null;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  admin_verification: boolean;
  cometchat_uid: string | null;
  profile_photo: string | null;
  cover_photo: string | null;
  has_subscriptions: boolean;
  is_following: boolean;
  is_subscribed: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface HashTag {
  hash_tag: string;
  indices: number[];
}

export interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: number[];
}

export interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}

export interface MediaMention {
  id: number;
  full_name: string;
  user_name: string;
}

export interface MentionedAccount {
  id: number;
  full_name: string;
  user_name: string;
  email: string | null;
  full_phone_number: string;
  country_code: string;
  phone_number: number;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  admin_verification: boolean;
  cometchat_uid: string | null;
  profile_photo: string | null;
  cover_photo: string | null;
  has_subscriptions: boolean;
  is_following: boolean;
  is_subscribed: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string | null;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface PostAttributes {
  id: number;
  connected_post_id: number | null;
  is_repost: boolean;
  reposted_by: string | null;
  body: string | null;
  body_mentions: BodyMention[];
  hash_tags: HashTag[];
  media: Media[];
  media_mentions: MediaMention[];
  location: string | null;
  created_at: string;
  updated_at: string;
  audience: string;
  is_locked: boolean;
  condition: string | null;
  is_reposted: boolean;
  is_saved: boolean;
  is_pinned: boolean;
  is_liked: boolean;
  total_likes: number;
  total_reposts: number;
  total_comments: number;
  impressions: number;
  model_name: string;
  external_url: string[];
  post_by: User;
  mentioned_accounts: MentionedAccount[];
  parent_post: PostAttributes | null;
}

export interface Post {
  id: string;
  type: string;
  attributes: PostAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes:any;
  checked:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  fetchedPages: number;
  activityFilter: string;
  token: string;
  accountId: number;
  pickerModalVisibility: boolean,
  activeIndex:number,
  followers:any[],
  anchorEl:null | HTMLElement;
  feeds:any[],
  pagination:any,
  feedType:string,
  isModel:boolean,
  suggestions:any[],
  tailoredMember:any[],
  page:number,
  tailoredPagination:any,
  suggestionsFilter:string
  activeSiggestion:number
  modelType:string,
  activeUser:any
  muteModel:boolean
  userInfo:any
  bottompoper:boolean
  suggestionsPagination:any
  showReportPostModal: boolean
  reportCatId: number|null;
  reportPostMainList: any[];
  reportPostSubList: any[];
  reportSubCatId: number|null;
  reportCurrentView: string;
  reportingPostId: string;
  reportPageLoading: boolean;
  reportPostSubmittedModal: boolean;
  postActivityModel:boolean
  activePostId:string
  showComments: boolean
  selectedPost: any
  postActivity:PostActivityInterface
  reportPostLoading: boolean
  showRepostModal: boolean
  showQuote: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
export interface ApiData{
  body?: {}
  method: string;
  contentType: string;
  endPoint: string;
}
// Customizable Area End

export default class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dummyVar: ActivityInterface[] = []
  
  getMainCatId: string = "";
  getSubCatId: string = "";
  getReportPostSubmitId: string = "";
  getActivityApiCallId: string = "";
  getFeedApiCallId:string = "";
  followApiCallId:string =""
  getSuggestionsApiCallId:string=""
  addUserApiCallId:string =""
  getTailoredMemberApiCallId:string =""
  tailoredMember:string=""
  removeUserApiCallId:string=""
  muteUserApiCallId:string =""
  getLoggedUserApiCallId:string =""
  unMuteUserApiCallId:string=""
  blockAccountApiCallId:string =""
  likePostApiCallId:string =""
  savePostApiCallId:string =""
  getPostActivityApiCallId:string =""
  repostId: string = ''
  undoRepostId: string = ''
  
  scrollParentRef = React.createRef<HTMLDivElement>();
  scrollSuggestionParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      fetchedPages: 1,
      activityFilter: "",
      token: "",
      accountId: 0,
      pickerModalVisibility: false,
      activeIndex:0,
      followers:[{name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""}
      ],
      feeds:[],
      anchorEl:null,
      pagination:{},
      feedType:"all",
      isModel:false,
      suggestions:[],
      tailoredMember:[],
      page:1,
      tailoredPagination:{},
      suggestionsFilter:"",
      activeSiggestion:0,
      modelType:"",
      activeUser:{},
      muteModel:false,
      userInfo:{},
      bottompoper:false,
      suggestionsPagination:{},
      showReportPostModal: false,
      reportPostMainList: [],
      reportPostSubList: [],
      reportCatId: null,
      reportSubCatId: null,
      reportCurrentView: "main",
      reportPageLoading: true,
      reportingPostId: "",
      reportPostSubmittedModal: false,
      postActivityModel:false,
      activePostId:"",
      showComments: false,
      selectedPost: null,
      postActivity:{
        "impressions": 0,
        "engagements": 0,
        "detail_expands": 0,
        "new_followers": 0,
        "profile_visits": 0
      },
      reportPostLoading: false,
      showRepostModal: false,
      showQuote: false,
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getFeeds()
    this.loggedUser()
  }

  setFilter=debounce((event:any)=> {    
    this.setState({ activityFilter: event.target.value, fetchedPages: 1, feeds: [] }, () => {
      this.getFeeds();
    });
  },300)

  handleSuggestionFilter = debounce((event:any)=> {    
    this.setState({ suggestionsFilter: event.target.value, page: 1, suggestions: [] }, () => {
     this.state.activeSiggestion ==1 ?this.getSuggestions():this.gettailoredUserList();
    });
  },300)


  setModalVisibility(value: boolean) {
    this.setState({ pickerModalVisibility: value })
  }

  handleClosePostActivityModel =()=>{
    this.setState({postActivityModel:false ,activePostId: ""})
  }

  handlePostActivityModel=(id?:string,postedById?:number)=>{  
    if(postedById === this.state.userInfo.id){
      this.setState({postActivityModel:true ,activePostId: id||""})
      this.getPostAcitivityData(id)
    }
  }

  getPostAcitivityData = async (id?:string) => {
    const token = await getStorageData("authToken")
    const url = configJSON.postaActivityEndpoint + `?post_id=${id}`

    this.getPostActivityApiCallId = sendAPIRequest(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
      }
    )
  }

  getFeeds = async () => {
    const token = await getStorageData("authToken")
    const url = configJSON.feedEndpoint + `?page=${this.state.fetchedPages}&per_page=10&type=${this.state.feedType}&query=${this.state.activityFilter}&sort_order=desc`

    this.getFeedApiCallId = sendAPIRequest(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
      }
    )
  }

  loggedUser = async () => {
    const token = await getStorageData("authToken")
    const url = configJSON.loggedUserEndpoint

    this.getLoggedUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
      }
    )
  }

  getSuggestions = async () => {
    const token = await getStorageData("authToken")
    const url = configJSON.suggestionsEndpoint + `?page=${this.state.page}&per_page=20&query=${this.state.suggestionsFilter} `

    this.getSuggestionsApiCallId = sendAPIRequest(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
      }
    )
  }

  muteConformation =() =>{
    this.setState({muteModel:true})
    this.handleClose()
  }

  handleMuteModel =(status:string) =>{
    if (status=="no"){
      this.setState({muteModel:false})
    }else{
      this.handleMuteAcoount(this.state.activeUser?.id)
    }
  }

  handleMuteAcoount = async (id: string) => {  
    const token = await getStorageData("authToken")
    const url = configJSON.muteUserEndpoint

    this.muteUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
        body: {
          account_id: id
        },
      }
    )
  }

  handleUnmuteUser = async (id:string) => { 
    const token = await getStorageData("authToken")
    const url = configJSON.unmuteUserEndpoint

    this.unMuteUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
        body: {
          account_id: id
        },
      }
    )
  }

  openModel =()=>{
    this.setState({isModel:true},()=>{
      this.getSuggestions()
    })
  }

  handleMemberModel=()=>{
    this.setState({isModel:true,modelType:"edit"})
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {
    let types = ["all","following","tailored"]
    
    this.setState({ activeIndex: newValue,feedType:types[newValue],fetchedPages:1,feeds:[],page:1 },async()=>{      
      if(types[newValue] === "tailored"){
        await this.gettailoredUserList()
        await this.getFeeds()
      } else this.getFeeds()   
    });
  };

  handleSuggestionTabsChange = (event: ChangeEvent<{}>, newValue: number) => {    
    this.setState({ activeSiggestion: newValue,page:1 },async()=>{  
      if(newValue==1){
       this.setState({suggestions:[]},()=>this.getSuggestions()) 
      }else{
        this.setState({tailoredMember:[]},()=>this.gettailoredUserList())
      }    
    });
  };

  handleAdduser = async (id: string) => {
    const token = await getStorageData("authToken")
    const url = configJSON.tailoredMemberEndpoint
    const formdata = new FormData();
    formdata.append("member_id",id)

    this.addUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'POST',
        headers: {
          token,
        },
        body: formdata,
      }
    )
  }

  handleRemoveUser = async(id:string) =>{
    const token = await getStorageData("authToken")
    const url = configJSON.tailoredMemberEndpoint
    const formdata = new FormData();
    formdata.append("member_id", id)

    this.removeUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'DELETE',
        headers: {
          token,
        },
        body: formdata,
      }
    )
  }

 gettailoredUserList = async() =>{
  const token = await getStorageData("authToken")
  const url = configJSON.tailoredMemberEndpoint + `?page=${this.state.page}&per_page=20&query=${this.state.suggestionsFilter}`

  this.getTailoredMemberApiCallId = sendAPIRequest(
    url,
    {
      method: 'GET',
      headers: {
        "Content-Type": configJSON.apiContentType,
        token,
      },
    }
  )
 }

  handleClick = (activeUser:string, postId?: string) => {
    this.setState({ activeUser:activeUser, reportingPostId: postId|| ""});
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleModelClose =()=>{
    this.setState({isModel:false,modelType:"",fetchedPages:1,feeds:[],suggestionsFilter:"",page:1},async()=>{
      await this.gettailoredUserList()
      await this.getFeeds()
      
    })
  }

  handleButtompoperlClose = ()=>{
    this.setState(
      {bottompoper:false, showComments: false, selectedPost: null}, 
      () => {
        this.dataFilter()
      }
    )
  }

  dataFilter =() =>{
    let feeds = [...this.state.feeds]
      let newarray = feeds.filter(item => this.state.activeUser?.id != item.attributes.post_by.id);
      this.setState({feeds:newarray})
  }


  handleMuteModelClose =()=>{
    this.setState({muteModel:false,})
  }

  handleFollow = async (id: number, type: string) => {
    const token = await getStorageData("authToken")
    const url = configJSON.followEndpoint

    this.followApiCallId = sendAPIRequest(
      url,
      {
        method: type == "follow" ? 'POST' : 'DELETE',
        headers: {
          "Content-Type": configJSON.apiContentType,
          token,
        },
        body: {
          account_id: id,
        }
      }
    )
  }

  handleLikePost = async(id:string,type:string,likeable_type:string) =>{
    this.setState({accountId:JSON.parse(id)})

    const token = await getStorageData("authToken")
    const url = configJSON.likePostEndpoint

    this.likePostApiCallId = sendAPIRequest(
      url,
      {
        method: type == "like" ? 'POST' : 'DELETE',
        headers: {
          "Content-Type": configJSON.apiContentType,
          token,
        },
        body: {
          likeable_id:id,
          likeable_type:likeable_type
        }
      }
    )
  }

  handleSavePost = async(id:string,type:string) =>{
    const token = await getStorageData("authToken")
    const url = type === "save" ? configJSON.savePostEndpoint : configJSON.unSavePostEndpoint

    this.savePostApiCallId = sendAPIRequest(
      url,
      {
        method: type == "save" ? 'POST' : 'DELETE',
        headers: {
          "Content-Type": configJSON.apiContentType,
          token,
        },
        body: {
          post_id: id,
        }
      }
    ) 
  }

  handleBlockAccount = async(id:number) =>{
    const token = await getStorageData("authToken")
    const url = configJSON.blockAccountEndpoint

    this.blockAccountApiCallId = sendAPIRequest(
      url,
      {
        method: 'POST',
        headers: {
          "Content-Type": configJSON.apiContentType,
          token,
        },
        body: {
          account_id: id,
        }
      }
    )
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if(errorResponse) {
      this.parseApiErrorResponse(errorResponse)
    }

    this.apiSuccessCallBackController(apiRequestCallId, responseJson)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getFeedApiCallId]: this.handleFeedsResponse,
      [this.getLoggedUserApiCallId]: this.handleLoggedUserResponse,
      [this.followApiCallId]: this.handleFollowResponse,
      [this.getSuggestionsApiCallId]: this.handleSuggestionsRes,
      [this.addUserApiCallId]: this.handleAddUserResponse,
      [this.removeUserApiCallId]: this.handleDeleteUser,
      [this.getTailoredMemberApiCallId]: this.handleTailoredMemberRes,
      [this.muteUserApiCallId]: this.handleMuteUsersRes,
      [this.unMuteUserApiCallId]: this.handleUnmuteRes,
      [this.blockAccountApiCallId]: this.handleBlockUser,
      [this.likePostApiCallId]: this.handleLikePostRes,
      [this.savePostApiCallId]: this.handleSavePostRes,
      [this.getMainCatId]: this.handleReportPostMainCat,
      [this.getSubCatId]: this.handleReportPostSubCat,
      [this.getReportPostSubmitId]: this.handleReportPostSubmit,
      [this.getPostActivityApiCallId]: this.handlePostActivityRes,
      [this.repostId]: this.handleRepostResponse,
      [this.undoRepostId]: this.handleUndoRepostResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleUndoRepostResponse = (responseJson: any) => {
    if (responseJson?.messages[0] === 'Your reposted post has been deleted') {
      const updateFeeds = [...this.state.feeds].filter(post => post.id !== this.state.selectedPost.id)
      this.setState({ feeds: updateFeeds})
    }
  }

  handleRepostResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      const feeds = [responseJson.data, ...this.state.feeds]
      const newFeed = feeds.map(post => {
        if (post.id === this.state.selectedPost?.id) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              total_reposts: post.attributes.total_reposts + 1
            }
          }
        }
        return post
       });
       return this.setState({ feeds: newFeed})
    }
    if (responseJson?.errors?.[0]) {
      toast.error(responseJson?.errors?.[0])
    }
  }

  handleFeedsResponse = (responseJson: any) => {
    if(responseJson && responseJson?.data) {
      this.setState({feeds:[...this.state.feeds,...responseJson.data], fetchedPages: this.state.fetchedPages + 1 , pagination:responseJson.pagination_details})
    }
  }

  handlePostActivityRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({postActivity:responseJson.data?.attributes?.post_activity})
    }
  }

  handleLikePostRes = (responseJson: any) => {
    if(responseJson && responseJson?.messages) {
      let feeds = [...this.state.feeds]
      let newarray = feeds.map(post => {
        if (post.attributes.id === this.state.accountId) {
          return {
                 ...post,
                 attributes: {
                   ...post.attributes,
                   is_liked:  responseJson.messages[0]=="Liked Successfully"?true:false,
                   total_likes:responseJson.messages[0]=="Liked Successfully"?post.attributes.total_likes+1:post.attributes.total_likes-1
                 }
               };
             }
             return post;
       });
       if (this.state.accountId.toString() === this.state.selectedPost?.id) {
        const updateCommentsPost = newarray?.find(post => post.id === this.state.selectedPost.id)
        this.setState({selectedPost: cloneDeep(updateCommentsPost)})
       }
       this.setState({feeds:newarray})    
    }
  }

  handleSavePostRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      let newfeeds = [...this.state.feeds]
      let feeds = newfeeds.map(post => {
        if (post.attributes.id == responseJson.data.id) {
          return {
                 ...post,
                 attributes: {
                   ...post.attributes,
                   is_saved: responseJson.data.attributes.is_saved
                 }
               };
             }
             return post;
       });
       if (responseJson.data.id === this.state.selectedPost?.id) {
        const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPost.id)
        this.setState({selectedPost: cloneDeep(updateCommentsPost)})
       }
       this.setState({feeds:feeds})    
    }
  }

  handleLoggedUserResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({userInfo:responseJson.data.attributes})    
    }
  }

  handleMuteUsersRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {      
      this.setState({muteModel:false,bottompoper:true})    
    }
  }

  handleBlockUser = (responseJson: any) => {
    if (responseJson && responseJson?.data) {      
      this.dataFilter()  
      this.handleCloseCommentsModal() 
      this.handleClose()
    }
  }

  handleUnmuteRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {      
      this.setState({bottompoper:false})    
    }
  }

  handleSuggestionsRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({suggestions:[...this.state.suggestions,...responseJson.data],page:this.state.page+1,suggestionsPagination:responseJson.pagination_details})
    }
  }

  handleAddUserResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      let suggestion = [...this.state.suggestions]        
      this.setState({suggestions:suggestion.filter(item => item.id !== responseJson.data.id)})
    }
  }

  handleDeleteUser = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      let tailoredMember = [...this.state.tailoredMember]        
      this.setState({tailoredMember:tailoredMember.filter(item => item.id !== responseJson.data.id)})
    }
  }

  handleTailoredMemberRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {      
      this.setState({tailoredMember:[...this.state.tailoredMember,...responseJson.data],page:this.state.page+1,tailoredPagination:responseJson.pagination_details})
    }
  }
  
  handleFollowResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
     let feed = [...this.state.feeds]    
    let newFeed = feed.map((post) =>
      post.attributes.post_by.id == responseJson.data.id
        ? {
            ...post,
            attributes: {
              ...post.attributes,
              post_by: {
                ...post.attributes.post_by,
                is_following: responseJson.data.attributes.is_following
              },
            },
          }
        : post
    );
    if (responseJson.data?.attributes?.id === this.state.selectedPost?.attributes.post_by.id) {
      const updateCommentsPost = newFeed?.find(post => post.attributes.post_by.id === this.state.selectedPost.attributes.post_by.id)
      this.setState({selectedPost: cloneDeep(updateCommentsPost)})
     }
      this.setState({feeds:newFeed },()=>this.handleClose()) 
    }
  }

  handleGetNewFeed = () => {
    this.setState({fetchedPages: 1, feeds: []})
    this.getFeeds()
  }

  handleReportPostMainCat = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({reportPostMainList: responseJson.data})
    }
  }

  handleReportPostSubCat = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({ reportPostSubList: responseJson.data, reportPostLoading: false })
    }
  }

  handleReportPostSubmit = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        reportCurrentView: "submit"
      })
    }
  }

  apiCall = (data:ApiData) => {
    const { contentType, method, endPoint, body} = data;
    const token = localStorage.getItem("authToken") || "";
     
    const header = { "Content-Type": contentType, token: token};
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  showReportPost = () => {
    this.handleClose();
    this.setState({
      showReportPostModal: true,
      reportPageLoading: true,
      anchorEl: null
    })
    this.getMainCatId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiMethod,
      endPoint: "bx_block_posts/report_issues"
    });
  }

  closeReportPostModal = () => {
    this.setState({
      showReportPostModal: false,
      reportCatId: null,
      reportSubCatId: null,
      reportingPostId: "",
      reportCurrentView: "main",
      reportPostSubList: []
    })
  }

  setReportPostMainCat = (id: number) => {
    this.setState({
      reportCatId: id
    })
  }
  setReportPostSubCat = (id: number) => {
    this.setState({
      reportSubCatId: id
    })
  }
  changeReportView =() =>{
    if(this.state.reportSubCatId !== null&& this.state.reportCatId !== null){
      this.getReportPostSubmitId = this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiPostMethod,
        endPoint: "bx_block_posts/report_post",
        body: {
          "report": {
            "post_id": Number(this.state.reportingPostId),
            "report_issue_id": this.state.reportCatId,
            "report_sub_issue_id": this.state.reportSubCatId
          }
        }
      });
    }else if(this.state.reportCatId !== null){
      this.setState({
        reportCurrentView: "subcat",
        reportPostLoading: true
      })
      this.getSubCatId = this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiMethod,
        endPoint: "bx_block_posts/report_sub_issues?id="+this.state.reportCatId,
      });
    }
  }

  handleClickComments = (postId: string) => {
    this.setState((prev) => {
      const selectedPost = prev.feeds?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedPost: selectedPost
      }
    })
  }

  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedPost: null})
  }

  handleUpdateNumberCommentOfPost = () => {
    const cloneFeeds = [...this.state.feeds]
    const updateFeeds = cloneFeeds.map((post) => {
      if (post.id === this.state.selectedPost?.id) {
        return {
          ...post,
          attributes: {
            ...post.attributes,
            total_comments: post.attributes.total_comments + 1
          }
        }
      } else {
        return post
      }
    })
    this.setState({feeds: updateFeeds})
  }

  handleShowRepostModal = (postId: string) => {
    this.setState((prev) => {
      const selectedPost = prev.feeds?.find(post => post.id === postId)
      return {
        ...prev, 
        showRepostModal: true,
        selectedPost: selectedPost
      }
    })
  }

  handleCloseRepostModal = () => {
    this.setState({showRepostModal: false})
  }

  handleRepost = async () => {
    const token = await getStorageData("authToken")
    
    this.repostId = sendAPIRequest(
      `bx_block_posts/repost`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
        body: {
          parent_post_id: this.state.selectedPost?.id
        },
      }
    )
    this.setState({showRepostModal: false})
  }

  handleUndoRepost = async () => {
    const token = await getStorageData("authToken")
    
    this.undoRepostId = sendAPIRequest(
      `bx_block_posts/undo_repost`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
        body: {
          parent_post_id: this.state.selectedPost?.attributes.parent_post.id
        },
      }
    )
    this.setState({showRepostModal: false})
  }

  handleQuote = () => {
    this.setState({showRepostModal: false, showQuote: true})
  }

  handleCloseQuote = () => {
    this.setState({showQuote: false})
  }

  handleGoBackAfterSubmit = () => {
    this.setState({fetchedPages: 1, feeds: [], showQuote: false})
    this.getFeeds()
  }
  // Customizable Area End
}

// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";
import MessageItemController, {
  Props,
} from "./MessageItemController.web";
import { profile } from "./assets";

export class MessageItem extends MessageItemController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, conversation, onClickConversation, isSelectingConversation } = this.props;

    return (
      <Box
        data-testid="conversation"
        className={classes.container} 
        onClick={() => onClickConversation(conversation)}
        style={{backgroundColor: isSelectingConversation ? '#1F1F1F' : 'unset'}}
      >
        <Box className={classes.infoContainer}>
          <img src={conversation.avatar || profile} width={48} height={48} style={{borderRadius: '50%'}} />
          <Box className={classes.infoWrapper}>
            <Typography className={classes.userName}>{conversation.name}</Typography>
            <Typography className={classes.lastMessage}>{conversation.lastMessage}</Typography>
          </Box>
        </Box>
        <Box>
          <Box className={classes.messageDetailsContainer}>
            {conversation.unreadMessageCount > 0 ? (
              <Typography className={classes.unreadMessage}> <span className="unreadcound">
                {conversation.unreadMessageCount}</span>
              </Typography>
            ) : (
              <Typography />
            )}
            <Typography className={classes.createdAt}>{conversation.lastMessageTimestamp}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    borderRadius: '10px',
  },
  infoContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: "4px",
  },
  userName: {
    fontSize: "16px",
    fontWeight: 600,
    textTransfer: "capitalize",
    fontFamily: "Manrope",
  },
  lastMessage: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#6B6B6B",
    fontFamily: "Manrope",
  },
  messageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: "10px",
  },
  unreadMessage: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "white",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    textAlign: "center",
    lineHeight: "18.2px",
    padding: "3px",
    "& .unreadcound": {
      fontSize: "10px",
      fontWeight: "400",
      width: "6px",
      height: "12px",
      fontFamily: "Manrope",
    }
  },
  createdAt: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Manrope",
  }
});

export default withStyles(styles)(MessageItem);
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod="PATCH";
exports.httpDeleteMethod="DELETE";
exports.jsonApiContentType="application/json";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.savePostEndpoint ="bx_block_posts/save_post"
exports.unSavePostEndpoint ="bx_block_posts/unsave_post"
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.followEndpoint ="bx_block_followers/follows";
exports.muteUserEndpoint = "bx_block_followers/mute_account"
exports.unmuteUserEndpoint = "bx_block_followers/unmute_account"
exports.blockAccountEndpoint = "bx_block_followers/block_account";
exports.getLoggedUserInfo="account_block/accounts/logged_user"; 
exports.getUserProfile = "account_block/accounts"
exports.getReportIssues= "bx_block_posts/report_issues";
exports.getReportSubIssues= "bx_block_posts/report_sub_issues?id=";
exports.ReportPost="bx_block_posts/report_post";
exports.likePostEndpoint ="bx_block_like/likes";
exports.getUserPosts="bx_block_posts/account_posts";
exports.postaActivityEndpoint = "bx_block_posts/post_activity"
exports.notificationPreference="bx_block_notifsettings/post_notification_preference?"
exports.getCustomizableProfileFieldsEndPoint ="bx_block_profile/profile/custom_user_profile_fields";
exports.getArchiveStories="bx_block_broadcast_message/stories/archives";
exports.getAccountHighlightEndPoint="bx_block_broadcast_message/account_highlights";
exports.addHighlightEndPoint="bx_block_broadcast_message/highlights";
exports.otpApiEndpoint="account_block/change_email_or_phone";
exports.updateProfile="account_block/update_profile";
exports.apiVerifyOtpMethod ="PATCH"
  exports.welocomeMesage = "Welcome to";
  exports.arrowsText = "ARROWS"; 
  exports.getProgileDataEndPoint = "account_block/accounts/logged_user";  
  exports.picError='please upload jpg/png files';
  exports.websiteRegex = /^https?:\/\/[a-zA-Z0-9-]+\.(com|org|net)(\/[a-zA-Z0-9-]+)?(\?[a-zA-Z0-9-_=&]*)?$/
  exports.phoneRegExpression = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  exports.emailRegex=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  exports.updateEmailEndpoint = "account_block/get_otp";
  exports.otpFieldError='please enter otp';
  exports.enterValidOtp='please enter valid otp';
  exports.otpRegex=/^\d{4}$/;
  
  
// Customizable Area End
